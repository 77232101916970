<template>
  <form @submit.prevent="submit">
    <InputPhone
      v-model="form.phone"
      :placeholder="`${$t('views.auth.sign-up.phone')}*`"
      :errors="errors.phone"
      :is-active="form.phone?.length"
      no-update
      auto-focus
    />

    <InputEmail
      :placeholder="`${$t('views.auth.sign-up.email')}*`"
      :errors="errors.email"
      v-model="form.email"
    />

    <InputText
      :placeholder="`${$t('views.auth.sign-up.surname')}*`"
      :max="30"
      :reg-exp="/[^a-z-A-Z-а-я-А-Я-ёЁ-їЇ -]/g"
      :errors="errors.surname"
      v-model="form.surname"
    />

    <InputText
      :placeholder="`${$t('views.auth.sign-up.name')}*`"
      :max="30"
      :reg-exp="/[^a-z-A-Z-а-я-А-Я-ёЁ-їЇ -]/g"
      :errors="errors.name"
      v-model="form.name"
    />

    <InputPassword
      :placeholder="`${$t('views.auth.sign-up.password')}*`"
      :progress="_passwordProgression"
      :is-progress-error="_errorPasswordProgression"
      :errors="errors.password"
      v-model="form.password"
    />

    <InputPassword
      :placeholder="`${$t('views.auth.sign-up.repeat-password')}*`"
      :errors="errors.repeat_password"
      v-model="form.repeat_password"
    />

    <InputText
      :placeholder="$t('views.auth.sign-up.referral')"
      :errors="errors.referral"
      :info="_referralCodeInfo"
      :reg-exp="/[^a-z-A-Z0-9]/g"
      v-model="form.referral"
    />
    <!-- :reg-exp="/\D/g" -->

    <InputCheckbox
      v-model="form.check1"
    >
      {{ $t('views.auth.sign-up.old') }}

      <RouterLink
        :to="{
          name: 'Rules',
          query: $route.query,
        }"
      >
        {{ $t('views.auth.sign-up.public-offer') }}
      </RouterLink>
    </InputCheckbox>

    <InputCheckbox
      v-model="form.check2"
    >
      {{ $t('views.auth.sign-up.notifications') }}
    </InputCheckbox>

    <BlueButton
      class="sign-up__button"
      :text="$t('views.auth.sign-up.creare')"
      :loading="loading"
      type="submit"
      :disabled="!form.check1 || loading"
    />

    <RouterLink
      :to="{ name: 'SignIn' }"
      class="sign-up__link"
    >
      {{ $t('views.auth.sign-up.registered') }}
    </RouterLink>
  </form>
</template>

<script setup>
import { ref, onMounted, computed, watch, getCurrentInstance } from 'vue'
import { useRouter, useRoute } from 'vue-router'
import { useStore } from 'vuex'
import { useI18n } from 'vue-i18n'
import { useVuelidate } from '@vuelidate/core'
import { required, email, minLength } from '@vuelidate/validators'

import InputPhone from '@/ui/inputs/Phone.vue'
import InputEmail from '@/ui/inputs/Email.vue'
import InputText from '@/ui/inputs/Text.vue'
import InputPassword from '@/ui/inputs/Password.vue'
import InputCheckbox from '@/ui/inputs/CheckBox.vue'
import BlueButton from '@/ui/buttons/BlueButton.vue'

const $router = useRouter()
const $route = useRoute()
const $store = useStore()
const { t } = useI18n()
const app = getCurrentInstance()
const { appContext: { config: { globalProperties: { $axios, $gtm } } } } = app

const loading = ref(false)

const form = ref({
  phone: null,
  email: null,
  surname: null,
  name: null,
  password: null,
  repeat_password: null,
  referral: null,
  check1: true,
  check2: true
})

const errors = ref({
  phone: [],
  email: [],
  surname: [],
  name: [],
  password: [],
  repeat_password: [],
  referral: []
})

const rules = computed(() => ({
  phone: { required },
  email: { required, email },
  surname: { required },
  name: { required },
  password: { required, minLength: minLength(6) },
  repeat_password: { required }
}))

const v = useVuelidate(rules, form)

const _referralCodeInfo = computed(() => (
  form.value.referral?.length >= 1 ? [t('info.referral-code')] : []
))

const _passwordProgression = computed({
  get () {
    const pass = form?.value?.password ? form?.value?.password : ''
    const value = []

    const checkName = pass?.toLowerCase()
      .indexOf(form?.value?.name?.replace(/\s+/g, '').toLowerCase()) !== -1

    const splitEmail = form?.value?.email?.split('@')
    const checkEmail = pass
      .toLowerCase()
      .indexOf(typeof splitEmail === 'object' && splitEmail.length > 0
        ? splitEmail[0].toLowerCase() : form?.value?.email?.toLowerCase()) !== -1

    if (checkName || checkEmail) {
      value.push({
        icon: 'error',
        text: 'errors.password-progress-no-copy'
      })
    } else {
      value.push({
        icon: 'success',
        text: 'errors.password-progress-no-copy'
      })
    }

    if (!(/[a-zA-Z]/.test(pass))) {
      value.push({
        icon: 'error',
        text: 'errors.password-progress-letter'
      })
    } else {
      value.push({
        icon: 'success',
        text: 'errors.password-progress-letter'
      })
    }

    if (!(/[A-Z]/.test(pass))) {
      value.push({
        icon: 'error',
        text: 'errors.password-progress-letter-upper'
      })
    } else {
      value.push({
        icon: 'success',
        text: 'errors.password-progress-letter-upper'
      })
    }

    if (!(/[0-9]/.test(pass))) {
      value.push({
        icon: 'error',
        text: 'errors.password-progress-number'
      })
    } else {
      value.push({
        icon: 'success',
        text: 'errors.password-progress-number'
      })
    }

    if (pass.length < 6) {
      value.push({
        icon: 'error',
        text: 'errors.password-progress-min'
      })
    } else {
      value.push({
        icon: 'success',
        text: 'errors.password-progress-min'
      })
    }

    return value
  }
})

const _errorPasswordProgression = computed({
  get () {
    var count = 0

    for (const { icon } of _passwordProgression.value) {
      if (icon === 'error') {
        count += 1
      }
    }

    return count > 0
  }
})

function _referral () {
  const localQuery = localStorage.getItem('query') || null
  const { referral } = (localQuery && JSON.parse(localQuery)) || $route.query || {}

  form.value.referral = referral
}

function setError () {
  const errorsForm = v.value
  const state = form.value

  errors.value = {
    phone: [],
    email: [],
    surname: [],
    name: [],
    password: [],
    repeat_password: [],
    referral: []
  }

  // Phone
  if (errorsForm.phone.required.$invalid) {
    errors.value.phone.push('errors.required')
  }

  // Email
  if (errorsForm.email.required.$invalid) {
    errors.value.email.push('errors.required')
  }
  if (errorsForm.email.email.$invalid) {
    errors.value.email.push('errors.email')
  }

  // Surname
  if (errorsForm.surname.required.$invalid) {
    errors.value.surname.push('errors.required')
  }
  if (state.surname?.length > 0) {
    const surnameLetter = state.surname.toLowerCase().replace(/[^a-zа-яёї]/g, '')

    if (surnameLetter.length < 2) {
      errors.value.surname.push('errors.min-letter')
    }
  }

  // Name
  if (errorsForm.name.required.$invalid) {
    errors.value.name.push('errors.required')
  }
  if (state.name?.length > 0) {
    const nameLetter = state.name.toLowerCase().replace(/[^a-zа-яёї]/g, '')

    if (nameLetter.length < 2) {
      errors.value.name.push('errors.min-letter')
    }
  }

  // Password
  if (_errorPasswordProgression.value) {
    errors.value.password.push('errors.required')
  }

  // Repeat password
  if (errorsForm.repeat_password.required.$invalid) {
    errors.value.repeat_password.push('errors.required')
  }
  if (state.repeat_password !== state.password) {
    errors.value.repeat_password.push('errors.password')
  }
}

function _countError () {
  const state = errors.value
  var count = 0

  count += state.phone.length +
    state.email.length + state.surname.length +
      state.name.length + state.password.length +
        state.repeat_password.length

  return count
}

async function submit () {
  loading.value = true
  v.value.$touch()
  setError()

  if (_countError() === 0) {
    const formData = new FormData()
    formData.append('phone', form.value.phone.replace(/\D/g, ''))
    formData.append('email', form.value.email)
    formData.append('surname', form.value.surname)
    formData.append('firstname', form.value.name)
    formData.append('password', form.value.password)
    formData.append('fingerprint_hash', $store.state.user.fingerprint)

    if (form.value.referral?.length) {
      formData.append('referrer_id', form.value.referral)
    }

    const query = localStorage.getItem('query')
    var utmData = (query && JSON.parse(query)) || {}

    Object.keys(utmData)?.length &&
      formData.append('utm_data', JSON.stringify(utmData))

    try {
      const resp = await $axios.post(
        '/auth/register', formData)

      if (resp.status === 200) {
        $gtm.push({
          event: 'Register',
          email: form.value.email
        })

        $router.push({
          name: 'SignUpConfirm',
          query: { q: form.value.email }
        })
      }
    } catch (error) {
      const { response: { data: { messages } } } = error

      if (messages === 'Phone already exists') {
        errors.value.phone.push('errors.phone-used')
      }

      if (messages.referrer_id && messages.referrer_id[0] === 'User id is wrong') {
        errors.value.referral.push('errors.referral-code')
      }

      if (messages.phone[0] === 'The phone field is required.') {
        errors.value.phone.push('errors.phone')
      }

      throw new Error(error)
    } finally {
      loading.value = false
    }
  }

  loading.value = false
}

async function checkEmail () {
  v.value.email.$touch()
  const errorsEmail = v.value.email

  errors.value = {
    email: []
  }

  if (!errorsEmail.$invalid) {
    const formData = new FormData()
    formData.append('email', form.value.email)

    try {
      await $axios.post('auth/check-email', formData)
    } catch (error) {
      if (!error.success) {
        errors.value.email.push({
          type: 'html-link',
          link: {
            name: 'SignIn',
            query: {
              email: form.value.email,
              emailTaken: true
            }
          },
          text: t(
            'errors.email-taken',
            { value: form.value.email })
        })
      }

      throw new Error(error)
    }
  }
}

watch(() => $route.params.locale, () => {
  setError()
})

let debounceEmailCheck = ref(null)
watch(() => form.value.email, () => {
  clearTimeout(debounceEmailCheck)

  debounceEmailCheck = setTimeout(() => {
    checkEmail()
  }, 1000)
})

onMounted(() => {
  _referral()
})
</script>

<style lang="scss" scoped>
form {
  display: flex;
  flex-direction: column;
  gap: 15px;

  .sign-up__button {
    margin: 5px 0 20px;
  }

  .sign-up__link {
    width: 100%;
    display: flex;
    justify-content: center;
    color: rgb(47, 87, 233);
    font-family: SemiBold;
    font-size: 14px;
    line-height: 100%;
    text-transform: uppercase;
    text-decoration: none;
  }
}
</style>
