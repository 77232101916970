<template>
  <div class="checkbox">
    <input
      type="checkbox"
      :id="id"
      class="checkbox__hide"
      :value="modelValue"
      @input="$emit('update:modelValue', $event.target.checked)"
    >

    <label
      :for="id"
      class="checkbox__label"
      :class="{
        active: modelValue
      }"
    >
      <div class="checkbox__label__point" />

      <div class="checkbox__label__content">
        <slot />
      </div>
    </label>
  </div>
</template>

<script setup>
import { ref, defineProps } from 'vue'
import { string } from '@/utils/random.js'

defineProps({
  modelValue: {
    type: Boolean,
    default: false
  }
})

const id = ref(`checkbox-${string(7)}`)
</script>

<style lang="scss" scoped>
.checkbox {
  &__label {
    width: 100%;
    display: flex;
    align-items: flex-start;
    cursor: pointer;

    &__content {
      &::v-deep {
        margin: 0;
        color: rgb(32, 37, 57);
        font-family: Medium;
        font-size: 12px;
        line-height: 130%;

        a {
          text-decoration: underline;
          color: inherit;
          font-family: inherit;
          font-size: inherit;
          line-height: inherit;
        }
      }
    }

    &:hover {
      .checkbox__label__point {
        border: 1px solid rgb(47, 87, 233);
      }
    }

    &.active {
      .checkbox__label__point {
        border: 4px solid rgb(47, 87, 233);
      }
    }

    &__point {
      height: 14px;
      width: 14px;
      min-width: 14px;
      margin-right: 6px;
      border-radius: 50%;
      background: white;
      border: 1px solid #787C8D;
      transition: .2s;
    }
  }

  &__hide {
    position: absolute;
    height: 1px;
    width: 1px;
    opacity: 0;
  }
}
</style>
